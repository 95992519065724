
.datatable{
    padding: 0;
    width: 100%;
    text-align: center;
    border-collapse: collapse;
}
.datatable th{
    padding: 0;
    background-color: #144A94;
    color: #fff;
    border: 1px solid;
    border-color: #999;
}
 .datatable tr {
    padding: 0px;
    height: 50px;
    
}
.datatable td{
    border: 1px solid;
    border-color: #999;
}
.datatable tr:nth-of-type(2n+1){
    background-color: #dddddd;
}