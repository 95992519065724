/* Scrollbar styling for Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: gray #FFF;
}

/* Scrollbar styling for Chrome */
*::-webkit-scrollbar {
    height: 12px;
    width: 12px;
}

*::-webkit-scrollbar-track {
    margin: 12px;
    border-radius: 12px;
}

*::-webkit-scrollbar-thumb {
    background-color: gray;
    border-radius: 12px;
}

/* Input number styling for Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

/* Input number styling for Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

html, body {
    /* height: 100%; */
    margin: 0;
    padding: 0;
}

/* Override Jodit Editor css */
.editorCss {
    background-color: #F2F2F2;
    text-align: center;
}

/* Modals-------------------------------------------------------------------------------------------------------------- */
.popUpModal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #FFF;
    border-radius: 12px;
    box-shadow: 0 0 3px #9E9E9E;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

/* For scrollable modals, need to padding an extra 12px from the left to account for the width of the scrollbar */
.horizontalScrollable {
    overflow-y: scroll;
    overflow-x: hidden;
    padding-left: 12px;
}

/* Override Swal2 z-index */
.swalContainer {
    z-index: 10000 !important;
}

/* Override MUI menu list height */
.MuiMenu-list {
    max-height: 300px;
}

/* Pages CSS ------------------------------------------------------------------------------- */
.wrapperBox {
    flex-grow: 1;
}

.firstRowContainer {
    padding: 12px
}

.dashboardAndSelfBtnDiv {
    display: flex;
    align-items: center;
}

.viewAndFilterBtnDiv {
    margin-right: 80px;
}

.tableContainer, .formContainer {
    padding-inline: 42px;
    margin-top: 30px;
}

.searchAndBtnContainer {
    margin-top: 24px;
}

.endBtnDiv {
    margin-right: 40px;
}