.select-wrapper{
    width: 50%;
    margin: 0 auto;
}
.select-wrapper select{
    margin-bottom: 5%;
    width: 100%;
    padding: 15px;
    border: none;
    border-radius: 18px;
    background-color: #dddddd;
}
.all-users{
    margin-bottom: 5%;
    font-size: 13px;
    width: 70%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
}
.all-users .single-user{
    
    margin-bottom: 5%;
    border: 1px solid;
    border-radius: 12px;
    padding: 12px;
    padding-top: 0px;
    padding-bottom: 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.single-user .select-boxes{
    width: 60%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.select-boxes .box{
    margin-left: 5%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
}
.footer{
    display: flex;
    justify-content: flex-end;
    width: 90%;
    margin: 0 auto;
    margin-bottom: 2%;
}
.footer-btn{
    background-color: #5E75C3;
    color: white;
}
.footer-btn:hover{
    background-color: #5E75C3;
}